import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Carpòfor de 5 a 15 cm de forma subglobosa o piriforme i de color grogós o marró grogós que es perllonga en una espècie de peu molt variable, de nul a fins a 8 cm. La superfície és dura, rugosa i trencadissa. L’interior està format per molts de peridis irregulars i disposats en capes ben diferenciades: la inferior amb peridis immadurs de color groc, a la mitjana de color marró negrós i la superior de color terrós. Les espores són marrons en massa, esfèriques, cobertes d’espinetes, de 8-10 micres, ferruginoses que surten per la part superior del carpòfor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      